<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent :id="model?.system?.id" :component="!model?.system?.workflow">
      <div class="insight-card">
        <GenericCard
          :card-url="clickUrl"
          :category="data.category"
          :content="data.content"
          :cta-link="data.ctaLink"
          :custom-element-content="data.customElementContent"
          :date="data.date"
          :description="data.description"
          :image="data.image"
          :title="data.title"
        />
      </div>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import type { InsightPageModel } from '~/models'

const props = defineProps<{ model: InsightPageModel }>()
const pageStore = usePageStore()

const data = {
  image:
    props.model?.elements.cardImage.value.length > 0
      ? props.model.elements.cardImage.value[0].url
      : '',
  date: props.model?.elements.publishingDate.value ?? '',
  category: props.model?.elements.insightCategory.value
    ? props.model?.elements.insightCategory.value[0]?.name
    : '',
  title: props.model?.elements?.title?.value ?? '',
  description: props.model?.elements.shortDescription.value ?? '',
  ctaLink: (await pageStore.getUrlByItemId(props.model?.system?.id)) ?? '',
  content: props.model?.elements.content.value ?? '',
  customElementContent: props.model?.elements.richTextInterpreterRichText.value
}
const clickUrl = ref<string>('')
pageStore.getUrlByItemId(props.model.system.id).then((url) => {
  if (url) clickUrl.value = url
})
</script>
